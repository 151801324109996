import React, {createContext, useContext, useState} from 'react';

// Create the context
export const SelectedContext = createContext();
export const useSelectedContext = ()=>{
    return useContext(SelectedContext)
}

// Create a provider component
export const SelectedProvider = ({ children }) => {
    const [selected, setSelected] = useState(-1);

    // Function to update the number

    return (
        <SelectedContext.Provider value={{selected,setSelected}}>
            {children}
        </SelectedContext.Provider>
    );
};