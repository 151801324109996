import React, {useEffect, useState} from 'react';
import {apiRoot} from "../../util";

/**
 * {
 *             groupid: number,
 *             groupname: string,
 *             permissions: [{
 *                 permissionId: number,
 *                 permissionName: string
 *             }]
 *         }
 */

const GroupManager = () => {
    const [permissions, setPermissions] = useState([])
    const [error,setError]=useState(<></>)
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const createGroup = async () => {
        let groupname = document.getElementById("grpnm").value;
        let res = await fetch(apiRoot+"/api/addGroup",{method:"POST",credentials: 'include',body:JSON.stringify({description:groupname}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            if(res.status===409){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>Group already exists</h3>
                    <p></p>
                </div>)
                return
            }
            alert("unknown error")
            return
        }
        let grps = await load()
        setSelectedGroup(grps.filter(g=>{return g.groupname===groupname})[0])
        document.getElementById("grpnm").value = ""
    }
    const updateSelectedGroup = () => {
        setError(<></>)
        let selectedId = document.getElementById("groups").value
        let selGroup = groups.filter(g => {
            return g.groupid === Number(selectedId)
        })
        setSelectedGroup(selGroup.length === 1 ? selGroup[0] : null)
    }
    const deleteSelectedGroup = async() => {
        let confirmed = window.confirm("Are you sure you want to delete this group?")
        if(!confirmed)return
        let res = await fetch(apiRoot+"/api/removeGroup/"+selectedGroup.groupid,{credentials:"include",method:"DELETE"})
        if(res.status!==200){
            if(res.status===403){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>Cannot delete group "user" or "admin"</h3>
                    <p></p>
                </div>)
                return
            }
            alert("ERROR")
            return
        }
        await load()
        setSelectedGroup(null)
    }
    const assignPermission = async() => {
        setError(<></>)
        const permElem = Number(document.getElementById("addper").value)
        if(selectedGroup.permissions.filter(p=>{return p.permissionId===permElem}).length!==0)return
        if (permElem === -1) return
        let res = await fetch(apiRoot+"/api/togglePermission",{method:"POST",credentials: 'include',body:JSON.stringify({groupId:selectedGroup.groupid,permissionId:permElem}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            alert("ERROR")
            return
        }
        let grps = await load()
        setSelectedGroup(grps.filter(g=>{return g.groupid===selectedGroup.groupid})[0])
    }
    const removePermissionEntry = async (permissionId) => {
        setError(<></>)
        if(selectedGroup.permissions.filter(p=>{return p.permissionId===permissionId}).length===0)return
        let res = await fetch(apiRoot+"/api/togglePermission",{method:"POST",credentials: 'include',body:JSON.stringify({groupId:selectedGroup.groupid,permissionId:permissionId}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            alert("ERROR")
            return
        }
        let grps = await load()
        setSelectedGroup(grps.filter(g=>{return g.groupid===selectedGroup.groupid})[0])
    }
    const load = async()=> {
        setError(<></>)
        let res = await fetch(apiRoot+"/api/groups", {method: "GET", credentials: 'include'})
        if (res.status !== 200) {
            setError(<div className={"error"}>
                <h1>ERROR</h1>
                <h2>You need to be admin</h2>
            </div>)
            return;
        }
        res = await res.json()
        res = res.map(g=>{return{groupid:g.group_id,groupname:g.description,permissions:g.permissions.map(p=>{return{permissionId:p.permission_id,permissionName:p.description}})}})
        let grps = res
        setGroups(res)
        res = await fetch(apiRoot+"/api/permissions", {method: "GET", credentials: 'include'})
        if (res.status !== 200) {
            setError(<div className={"error"}>
                <h1>ERROR</h1>
                <h2>You need to be admin</h2>
            </div>)
            return;
        }
        res = await res.json()
        res = res.map(p=>{return{permissionId:p.permission_id,permissionName:p.description}})
        setPermissions(res)
        return grps
    }
    const renameGroup = async()=>{
        let newName = document.getElementById("renameGP").value
        let res = await fetch(apiRoot+"/api/renameGroup",{method:"POST",credentials: 'include',body:JSON.stringify({id:selectedGroup.groupid,name:newName}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            if(res.status===409){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>A group with this name already exists</h3>
                    <p></p>
                </div>)
            }
            alert("ERROR")
            return
        }
        let grps = await load()
        setSelectedGroup(grps.filter(g=>{return g.groupid===selectedGroup.groupid})[0])
        document.getElementById("renameGP").value = ""
    }
    useEffect(() => {
        load()
    }, []);
    return (
        <div className={"settingpanel"}>
            {error}
            <div>
                <div>
                    <h2>Manage group</h2>
                    <label htmlFor="groups">Select group: </label>
                    <select id="groups" name="groups" onChange={updateSelectedGroup}>
                        <option value={-1} key={-1}>select group</option>
                        {
                            groups.map(g => {
                                return (
                                    <option value={g.groupid} key={g.groupid}>{g.groupname}</option>
                                )
                            })}
                    </select>
                </div>
                <div>
                    {selectedGroup === null || groups.length === 0 ? <h3>Select a Group to see options!</h3> : <>
                        <div className={"card"}>
                            <div>
                                <h3>Info</h3>
                                <p className={"userinfo"}>Selected Group:</p>
                                <p className={"userinfo"}> {selectedGroup.groupname}</p>
                            </div>
                            <div>
                                <p className={"userinfo"}>ID: {selectedGroup.groupid}</p>
                                <button className={"userinfo"} onClick={deleteSelectedGroup}>Delete group</button>
                            </div>
                        </div>
                        <div className={"card"}>
                            <h3>Rename Group</h3>
                            <input type="text" id="renameGP" placeholder="group name"/>
                            <br/>
                            <button onClick={renameGroup}>rename</button>
                        </div>
                        <br/>
                        <div className={"card"}>
                            <h3>Add group</h3>
                            <div>
                                <input type="text" id="grpnm" placeholder="group name"/>
                            </div>
                            <button onClick={createGroup}>Add group</button>
                        </div>
                        <div>
                            <div className={"card"}>
                                <h3 style={{fontSize: "21px"}}>Permissions:</h3>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                    </tr>
                                    {selectedGroup.permissions.map(p => {
                                        return (<tr>
                                            <td>{p.permissionId}</td>
                                            <td>{p.permissionName}</td>
                                            <td>
                                                <button onClick={() => {
                                                    removePermissionEntry(p.permissionId)
                                                }}>remove
                                                </button>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                                <div>
                                    <select id="addper" name="addper">
                                        <option value={-1} key={-1}>select permission</option>
                                        {
                                            permissions.map(p => {
                                                if(!selectedGroup.permissions.map(p2=>{return p2.permissionId}).includes(p.permissionId))
                                                return (
                                                    <option value={p.permissionId}
                                                            key={p.permissionId}>{p.permissionName}</option>
                                                )
                                                else return (<></>)
                                            })}
                                    </select>

                                    <button onClick={assignPermission}>Add permission</button>
                                </div>
                            </div>
                        </div>

                    </>
                    }
                </div>
            </div>
        </div>
    );
};

export default GroupManager;