import React, {useContext, useState} from 'react';
import {apiRoot, shaHash} from "../../util";
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "./UserContextProvider";


const LoginElement = () => {
    const navigate = useNavigate()
    const [failed, setFailed] = useState(false)
    const {updateUser} = useContext(UserContext)
    const handleLogin=async ()=>{
        let username = document.getElementById("usrnm").value
        let password = await shaHash(document.getElementById("pwd").value)
        let res = await fetch(apiRoot+"/api/login",{method:"POST", body:JSON.stringify({username: username, passHash: password}),headers:{'Content-Type': 'application/json'}})
        if(res.status!==200){
            setFailed(true)
            return
        }
        res = await res.json()
        updateUser(res.users)
        document.cookie = "session="+res.session
        navigate("/dashboard")
    }
    return (
        <div className={"workerCard"}>
            <h2 style={{marginTop: "auto"}}>Login</h2>
            {failed?<div className={"errorcard"}>Login failed: Incorrect credentials</div>:<></>}
            <div>
                <input type="text" id="usrnm" placeholder="username"/>
            </div>
            <div style={{marginTop: "5px"}}>
                <input type="password" id="pwd" placeholder="password"/>
            </div>
            <button onClick={handleLogin} style={{fontSize: "16px", marginTop: "5px"}}>Login</button>
            <br/>
            <Link to={"/register"}>Have no account? Register here</Link>
        </div>
    );
};

export default LoginElement;