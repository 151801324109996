import React, { createContext, useState } from 'react';

// Create a context with default value
export const OptionsContext = createContext([]);

export const OptionsContextProvider = ({ children }) => {
    // This state will hold the user object
    const [options, setOptions] = useState([]);

    // This function will update the user object
    const updateOptions = (newUser) => {
        setOptions(newUser);
    };

    return (
        <OptionsContext.Provider value={{ options, updateOptions }}>
            {children}
        </OptionsContext.Provider>
    );
};