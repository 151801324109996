import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import "../../styles.css"
import {apiRoot, hasPermission} from "../../util";
import MeasurePointComponent from "./measurepoints/MeasurePointComponent";
import {UserContext} from "../userManagement/UserContextProvider";
import {SketchPicker} from "react-color";


const WorkstationView = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [workstation, setWorkstation] = useState({name: "loading..."})
    const [statistic, setStatistic] = useState([])
    const [sgSizes, setSgSizes] = useState([])
    const [emptyMPs, setEmptyMPs] = useState([])
    const {user} = useContext(UserContext)
    const [color, setColor] = useState({
        h: 150,
        s: 0.50,
        l: 0.20,
        a: 1,
    })
    const [colorstring, setColorstring] = useState("")
    const [error, setError] = useState(<></>)

    const getData = async () => {
        let res = await fetch(apiRoot + "/api/workstation/" + id, {credentials: 'include'})
        if (res.status !== 200) return
        res = await res.json()
        setColorstring(res.color)
        let wstmp = res
        setWorkstation(res)
        let ws = res
        res = await fetch(apiRoot + "/api/statistics", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({wsId: ws.id, sgSize: 3}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) return
        res = await res.json()
        res = res.sort((a, b) => {
            return ("" + (a.measurePoint.name)).localeCompare(b.measurePoint.name)
        })
        for (let i = 0; i < res.length; i++) {
            res[i].i = i
        }
        let tempEmpty = []
        for (const measurePoint of wstmp.measurePoints) {
            if (!(res.map(a => {
                return a.measurePoint.mpId
            }).includes(measurePoint.mpId))) tempEmpty.push(measurePoint)
        }
        setEmptyMPs(tempEmpty)
        setStatistic(res)
        setSgSizes(Array(res.length).fill(3))
    }
    const updateMeasurePoint = async (i) => {
        let res = await fetch(apiRoot + "/api/statistics", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({wsId: workstation.id, sgSize: sgSizes[i]}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) return
        res = await res.json()
        res = res.sort((a, b) => {
            return ("" + (a.measurePoint.name)).localeCompare(b.measurePoint.name)
        })
        for (let i = 0; i < res.length; i++) {
            res[i].i = i
        }
        res = res.filter(r => {
            return r.i === i
        })[0]
        let tempStat = [...statistic]
        tempStat[i] = res
        setStatistic(tempStat)
    }
    useEffect(() => {
        const ab = () => {
            getData().then()
        }
        ab()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addMeasurePoint = async () => {
        let mpName = document.getElementById("mpName").value
        let res = await fetch(apiRoot + "/api/addMeasurepoint", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({owner: workstation.id, name: mpName}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
        document.getElementById("mpName").value = ""
    }
    const changeColor = async (c) => {
        setColor(c.hsl)
        setColorstring("hsl(" + c.hsl.h + "," + c.hsl.s*100 + "%," + c.hsl.l*100 + "%)")
    }
    const saveColor = async () => {
        let res = await fetch(apiRoot + "/api/changeColor", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({wsId: workstation.id, color: colorstring}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
        setError(<div className={"infocard"}>
            <h3>Successfully saved workstation color</h3>
        </div>)
        setTimeout(()=>{setError(<></>)},2500)
    }


    const deleteWorkstation = async () => {
        let confirmed = window.confirm("Are you sure you want to delete the workstation?")
        if (!confirmed) return
        let res = await fetch(apiRoot + "/api/deleteWorkstation/" + workstation.id, {
            credentials: 'include',
            method: "DELETE"
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        navigate("/dashboard")
    }
    const renameWorkstation = async () => {
        let newName = document.getElementById("wsName").value
        let res = await fetch(apiRoot + "/api/renameWorkstation/" + workstation.id, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(newName),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
        document.getElementById("wsName").value = ""
    }

    const deleteMeasurePoint = async (mp) => {
        let confirmed = window.confirm("Are you sure you want to delete the measure point?")
        if (!confirmed) return
        let res = await fetch(apiRoot + "/api/deleteMeasurePoint/" + mp.mpId, {
            credentials: 'include',
            method: "DELETE"
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
    }
//<h2  style={{color: "hsl("+color.h+","+color.s+","+color.l+")"}}>{"Workstation: " + workstation.name}</h2>
    return (
        <div>
            <h2 style={{color: colorstring}}>{"Workstation: " + workstation.name}</h2>
            {error}
            <div>{
                statistic.map(stat => {
                    return <MeasurePointComponent statistic={stat} getData={getData} workstation={workstation}/>
                })
            }
                {emptyMPs.length !== 0 ? <div className={"workerCard"}>
                    <h3>Empty Measure Points:</h3>
                    <ul>
                        {emptyMPs.map(mp => {
                            return (<li>{mp.name}
                                {hasPermission(user,"admin")||hasPermission(user,"manage workstations")?<button onClick={() => {
                                    deleteMeasurePoint(mp)
                                }}>delete
                                </button>:<></>}
                            </li>)
                        })}
                    </ul>
                </div> : <></>}
            </div>
            <hr/>
            {hasPermission(user, "admin") ? <div>
                <div className={"card"}>
                    <h3>Manage Workstation</h3>
                    <div>
                        <button style={{width: "100%", margin: "auto", marginTop: "10px"}}
                                onClick={deleteWorkstation}>Delete workstation
                        </button>
                    </div>
                    <div>
                        <h3>Rename Workstation</h3>
                        <input id={"wsName"} placeholder={"Name"}/>
                        <button onClick={renameWorkstation}>Rename</button>
                    </div>
                    <div>
                        <h3>Add Measurepoint</h3>
                        <input id={"mpName"} placeholder={"Name"}/>
                        <button onClick={addMeasurePoint}>Add</button>
                    </div>
                    <div>
                        <h3>Change color</h3>
                        <div style={{display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            marginTop: "5px"}}>
                            <SketchPicker
                                color={color}
                                onChange={changeColor}
                                disableAlpha={true}
                            />
                        </div>
                        <button onClick={saveColor}>Save</button>
                    </div>
                </div>
            </div> : <></>}
        </div>
    );
};

export default WorkstationView;