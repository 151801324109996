import React, {useEffect, useState} from 'react';
import {apiRoot, shaHash} from "../../util";
import "../../styles.css"

/**
 * {
 *     username: string,
 *     userid: number
 *     group:
 *         {
 *             groupid: number,
 *             groupname: string,
 *             permissions: [{
 *                 permissionId: number,
 *                 permissionName: string
 *             }]
 *         }
 *
 * }
 */

const UserManager = () => {
    const [users, setUsers] = useState([])
    const [error,setError]=useState(<></>)
    const [groups, setGroups] = useState([{
        groupid: 0,
        groupname: "testgrp1",
        permissions: []
    }, {
        groupid: 1,
        groupname: "testgrp2",
        permissions: []
    }])
    const [selectedUser, setSelectedUser] = useState(null)
    const createUser = async() => {
        setError(<></>)
        let username = document.getElementById("usrnm").value;
        let password = document.getElementById("passwd").value;
        let confirm = document.getElementById("repeatpasswd").value;
        if(confirm!==password){
            setError(<div className={"errorcard"}>
                <h2>Error</h2>
                <h3>Passwords do not match!</h3>
                <p></p>
            </div>)
            return
        }
        password = await shaHash(password)
        let res = await fetch(apiRoot+"/api/addUser",{method:"POST",credentials: 'include',body:JSON.stringify({username:username,passHash:password}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            if(res.status===409){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>user already exists</h3>
                    <p></p>
                </div>)
                return
            }
            alert("ERROR")
            return
        }
        let usrs = await load()
        document.getElementById("usrnm").value = ""
        document.getElementById("passwd").value = ""
        console.log(usrs)
        setSelectedUser(usrs.filter(u=>u.username===username)[0])
    }
    const updateSelectedUser = () => {
        setError(<></>)
        let selectedId = document.getElementById("users").value
        let selUser = users.filter(u => {
            return u.userid === Number(selectedId)
        })
        setSelectedUser(selUser.length === 1 ? selUser[0] : null)
        //console.log(selUser)
    }
    const deleteSelectedUser = async() => {
        setError(<></>)
        let confirmed = window.confirm("Are you sure you want to delete this user?")
        if(!confirmed)return
        let res = await fetch(apiRoot+"/api/deleteUser/"+selectedUser.userid,{method:"DELETE",credentials: 'include'})
        if(res.status!==200){
            if(res.status===403){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>You cannot delete yourself</h3>
                    <p></p>
                </div>)
                return
            }
            alert("ERROR")
            return
        }
        await load()
        setSelectedUser(null)
    }
    const assignGroup = async () => {
        setError(<></>)
        const groupElem = document.getElementById("addgrp").value
        if (groupElem === -1) return
        let filtGroups = groups.filter(g => {
            return g.groupid === Number(groupElem)
        })
        if (filtGroups.length !== 1) return
        let group = filtGroups[0]
        selectedUser.group = group
        let res = await fetch(apiRoot+"/api/changeGroup",{method:"POST",credentials: 'include',body:JSON.stringify({userId:selectedUser.userid,groupId:group.groupid}),headers:{"Content-Type":"application/json"}})
        if (res.status !== 200) {
            if(res.status===409){
                setError(<div className={"error"}>
                    <h2>ERROR</h2>
                    <h3>You cannot remove admin from yourself! </h3>
                </div>)
                return
            }
            setError(<div className={"error"}>
                <h2>ERROR</h2>
                <h3>You need to be admin</h3>
            </div>)
            return;
        }
        await load()
        setSelectedUser(users.filter(u=>u.userid===selectedUser.userid)[0])
    }
    const forceChangePassword = async ()=>{
        setError(<></>)
        let password = document.getElementById("newpasswd").value
        password = await shaHash(password)
        let res = await fetch(apiRoot+"/api/forcechangepass",{method:"POST",credentials: 'include',body:JSON.stringify({id:selectedUser.userid,password:password}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            alert("ERROR")
            return
        }
        document.getElementById("newpasswd").value = ""
    }
    async function load () {
        setError(<></>)
        let res = await fetch(apiRoot+"/api/users", {method: "GET", credentials: 'include'})
        if (res.status !== 200) {
            setError(<div className={"error"}>
                <h1>ERROR</h1>
                <h2>You need to be admin</h2>
            </div>)
            return;
        }
        res = await res.json()
        res = res.map(u=>{return{username:u.username,userid:u.user_id,group:{groupid:u.groups.group_id,groupname:u.groups.description}}})
        let usrs = res
        setUsers(res)
        res = await fetch(apiRoot+"/api/groups", {method: "GET", credentials: 'include'})
        if (res.status !== 200) {
            setError(<div className={"error"}>
                <h1>ERROR</h1>
                <h2>You need to be admin</h2>
            </div>)
            return;
        }
        res = await res.json()
        res = res.map(g=>{return{groupid:g.group_id,groupname:g.description}})
        setGroups(res)
        return usrs
    }
    const renameUser=async()=>{
        setError(<></>)
        let newName = document.getElementById("rename").value
        let res = await fetch(apiRoot+"/api/renameUser",{method:"POST",credentials: 'include',body:JSON.stringify({id:selectedUser.userid,name:newName}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            if(res.status===409){
                setError(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>A user with this name already exists</h3>
                    <p></p>
                </div>)
                return
            }
            alert("ERROR")
            return
        }
        await load()
        document.getElementById("rename").value = ""
    }
    useEffect(() => {

        load()
    }, []);
    useEffect(() => {
        let selectedId = document.getElementById("users").value
        if(selectedId===-1||selectedUser===null)return
        if(selectedId!==selectedUser.userid){
            document.getElementById("users").value = selectedUser.userid
        }
    }, [load,selectedUser]);
    return (
        <div className={"settingpanel"}>
            {error}
            <div>
                <div>
                    <h2>Manage user</h2>
                    <label htmlFor="users">Select user: </label>
                    <select id="users" name="users" onChange={updateSelectedUser}>
                        <option value={-1} key={-1}>select user</option>
                        {
                            users.map(u => {
                                return (
                                    <option value={u.userid} key={u.userid}>{u.username}</option>
                                )
                            })}
                    </select>
                </div>
                <div>
                    {selectedUser === null || users.length === 0 ? <h3>Select a User to see options!</h3> : <>
                        <div className={"card"}>
                            <div>
                                <h3>Info</h3>
                                <p className={"userinfo"}>Selected User:</p>
                                <p className={"userinfo"}>{selectedUser.username}</p>
                            </div>
                            <div>
                                <p className={"userinfo"}>ID: {selectedUser.userid}</p>
                                <button className={"userinfo"} onClick={deleteSelectedUser}>Delete user</button>
                            </div>
                        </div>
                        <div className={"card"}>
                            <h3>Rename User</h3>
                            <div>
                                <div>
                                    <input type="text" id="rename" placeholder="username"/>
                                </div>
                                <button onClick={renameUser}>rename</button>
                            </div>
                        </div>
                        <div>
                            <div className={"card"}>
                                <h3>Change password</h3>
                                <div>
                                    <div>
                                        <input type="password" id="newpasswd" placeholder="new password"/>
                                    </div>
                                    <button onClick={forceChangePassword}>change password</button>
                                </div>
                            </div>
                            <div className={"card"}>
                                <h3>Add user</h3>
                                <div>

                                    <input type="text" id="usrnm" placeholder="username"/>
                                    <br/>
                                    <input style={{marginTop: "5px"}} type="password" id="passwd" placeholder="password"/>
                                    <br/>
                                    <input style={{marginTop: "5px"}} type="password" id="repeatpasswd" placeholder="confirm password"/>
                                    <br/>
                                    <button onClick={createUser}>Add user</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={"card"}>
                                <h3 style={{fontSize: "21px"}}>Current group:</h3>
                                <div style={{alignContent: "center"}}>
                                    <h4>{selectedUser.group.groupname}</h4>
                                </div>

                                <div>
                                    <select id="addgrp" name="addgrp">
                                        <option value={-1} key={-1}>select group</option>
                                        {
                                            groups.map(g => {
                                                return (
                                                    <option value={g.groupid} key={g.groupid}>{g.groupname}</option>
                                                )
                                            })}
                                    </select>
                                    <button onClick={assignGroup}>Set group</button>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    );
};

export default UserManager;
