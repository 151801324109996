import React, {useContext} from 'react';
import WorkstationFlowChart from "./workstations/WorkstationFlowChart";
import {apiRoot} from "../util";
import {UserContext} from "./userManagement/UserContextProvider";

const Dashboard = () => {
    const {user} = useContext(UserContext)
    const addWorkstation = async()=>{
        let newName = document.getElementById("wsName").value
        let res = await fetch(apiRoot+"/api/addWorkstation",{method:"POST",credentials:"include",body:JSON.stringify({name:newName,predecessors:[],measurePoints:[]}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            alert("ERROR")
            return
        }
        setTimeout(()=>{window.location.reload()},500)
        document.getElementById("wsName").value = ""
    }
    return(
        <div>
            {user&&user.groups.permissions.map(p=>p.description).includes("admin")?<><div style={{height:"9vh", paddingRight:"20px",paddingLeft:"20px",paddingTop:"1px", margin:"1px"}} className={"card"}>
                <input placeholder={"name"} id={"wsName"} style={{marginTop:"5px"}}/>
                <br/>
                <button onClick={addWorkstation}>Add Workstation</button>
            </div>
            <hr/>
            </>:<></>}
            <div style={{height: user&&user.groups.permissions.map(p=>p.description).includes("admin")?'79vh':'89vh', width: '100vw'}}>
                <WorkstationFlowChart/>
            </div>
        </div>
    )
};

export default Dashboard;