import './App.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import MainPage from "./views/MainPage";
import UserManager from "./views/userManagement/UserManager";
import GroupManager from "./views/userManagement/GroupManager";
import LoginElement from "./views/userManagement/LoginElement";
import RegisterElement from "./views/userManagement/RegisterElement";
import Dashboard from "./views/Dashboard";
import SubmitView from "./views/workstations/SubmitView";
import SettingsView from "./views/userManagement/SettingsView";
import WorkstationView from "./views/workstations/WorkstationView";
import {UserContextProvider} from "./views/userManagement/UserContextProvider";
import AdminPanel from "./views/userManagement/AdminPanel";
import {OptionsContextProvider} from "./views/OptionsContextProvider";

function App() {

  return (
      <HashRouter>
        <Routes>
            <Route path="/" element={
                <UserContextProvider>
                    <OptionsContextProvider>
                        <MainPage />
                    </OptionsContextProvider>
                </UserContextProvider>
            } >
                <Route path="/users" element={<UserManager />} />
                <Route path="/groups" element={<GroupManager />} />
                <Route path="/login" element={<LoginElement />} />
                <Route path="/register" element={<RegisterElement />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/submit" element={<SubmitView />} />
                <Route path="/settings" element={<SettingsView />} />
                <Route path="/workstation/:id" element={<WorkstationView />} />
                <Route path="/admin" element={<AdminPanel/>} />
                <Route path="/measurepoint:id"/>
            </Route>
        </Routes>
      </HashRouter>

  );
}

export default App;
