import React, {useContext, useRef, useState} from 'react';
import ControlLimitsChart from "../ControlLimitsChart";
import {apiRoot, hasPermission} from "../../../util";
import {UserContext} from "../../userManagement/UserContextProvider";

const MeasurePointComponent = ({statistic, getData, workstation}) => {
    const [sgSize, setSGSize] = useState(3)
    const [stat, setStat] = useState(statistic)
    const sliderRef = useRef(null)
    const {user} = useContext(UserContext)
    const handleSgSizeChange = async () => {
        setSGSize(sliderRef.current.value)
    }

    const updateMeasurePoint = async () => {
        let res = await fetch(apiRoot + "/api/statistics?mpId=" + statistic.measurePoint.mpId, {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({wsId: workstation.id, sgSize: sgSize}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status === 200) {
            res = await res.json()
            if (res.length === 1) setStat(res[0])
        }
    }

    const renameMeasurePoint = async (mp) => {
        let mpName = document.getElementById("mpName" + mp.id).value
        let res = await fetch(apiRoot + "/api/renameMP", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({id: mp.mpId, name: mpName}),
            headers: {"Content-Type": "application/json"}
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
        document.getElementById("mpName" + mp.id).value = ""
    }
    const deleteMeasurePoint = async (mp) => {
        let confirmed = window.confirm("Are you sure you want to delete the measure point?")
        if (!confirmed) return
        let res = await fetch(apiRoot + "/api/deleteMeasurePoint/" + mp.mpId, {
            credentials: 'include',
            method: "DELETE"
        })
        if (res.status !== 200) {
            alert("ERROR")
            return
        }
        await getData()
    }

    return (
        <div className={"workerCard"}>
            <h3>{"Measure Point: \"" + statistic.measurePoint.name + "\""}</h3>
            <div className={"slider-container"}>
                <label htmlFor="slider">Subgroup size: {sgSize}</label>
                <input
                    type="range"
                    id={"slider" + statistic.measurePoint.mpId}
                    min="1"
                    max={(Math.min(statistic.measurePoint.measuredValues.length, 25)) + ""}
                    defaultValue={sgSize}
                    ref={sliderRef}
                    onMouseUp={() => {
                        updateMeasurePoint()
                    }}
                    onChange={() => {
                        handleSgSizeChange()
                    }}
                />
            </div>
            <table>
                <tbody>
                <tr>
                    <td>Average:</td>
                    <td>{stat.average.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Average range:</td>
                    <td>{stat.averageRange.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Minimum:</td>
                    <td>{stat.minimum}</td>
                </tr>
                <tr>
                    <td>Minimum range:</td>
                    <td>{stat.minimumRange}</td>
                </tr>
                <tr>
                    <td>Maximum:</td>
                    <td>{stat.maximum}</td>
                </tr>
                <tr>
                    <td>Maximum range:</td>
                    <td>{stat.maximumRange}</td>
                </tr>
                </tbody>
            </table>
            <p>Averages per subgroup:</p>
            <ControlLimitsChart data={stat.avgSGs}
                                controlLimits={{upper: stat.upperX, lower: stat.lowerX}}/>
            <p>Ranges per subgroup:</p>
            <ControlLimitsChart data={stat.rangeSGs}
                                controlLimits={{upper: stat.upperR, lower: stat.lowerR}}/>
            {hasPermission(user, "admin") || hasPermission(user, "manage workstations") ? <>
                <div>
                    <input id={"mpName" + stat.measurePoint.id} placeholder={"Name"}/>
                    <button onClick={() => {
                        renameMeasurePoint(stat.measurePoint)
                    }}>Rename measurepoint
                    </button>
                </div>
                <div>
                    <button onClick={() => {
                        deleteMeasurePoint(stat.measurePoint)
                    }}>Delete measurepoint
                    </button>
                </div>
            </> : <></>}
        </div>
    );
};

export default MeasurePointComponent;