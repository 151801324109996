export const shaHash = async (input) => {
    // Constants [first 32 bits of the fractional parts of the cube roots of the first 64 prime numbers]
    const K = [
        0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5,
        0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
        0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3,
        0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
        0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc,
        0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
        0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7,
        0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
        0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13,
        0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
        0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3,
        0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
        0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5,
        0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
        0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208,
        0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2
    ];

    const H = [
        0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a,
        0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19
    ];

    const ROTR = (n, x) => (x >>> n) | (x << (32 - n));
    const Σ0 = x => ROTR(2, x) ^ ROTR(13, x) ^ ROTR(22, x);
    const Σ1 = x => ROTR(6, x) ^ ROTR(11, x) ^ ROTR(25, x);
    const σ0 = x => ROTR(7, x) ^ ROTR(18, x) ^ (x >>> 3);
    const σ1 = x => ROTR(17, x) ^ ROTR(19, x) ^ (x >>> 10);

    const toHexString = n => n.toString(16).padStart(8, '0');

    const preProcess = message => {
        const m = [];
        for (let i = 0; i < message.length; i++) {
            m[i >> 2] |= (message.charCodeAt(i) & 0xff) << ((3 - i) % 4 * 8);
        }
        m[message.length >> 2] |= 0x80 << ((3 - message.length) % 4 * 8);
        m[(((message.length + 8) >> 6) << 4) + 15] = message.length * 8;
        return m;
    };

    const processChunk = (m, H) => {
        const W = new Array(64);
        for (let i = 0; i < 16; i++) W[i] = m[i];
        for (let i = 16; i < 64; i++) W[i] = σ1(W[i - 2]) + W[i - 7] + σ0(W[i - 15]) + W[i - 16] | 0;

        let [a, b, c, d, e, f, g, h] = H;

        for (let i = 0; i < 64; i++) {
            const T1 = h + Σ1(e) + ((e & f) ^ (~e & g)) + K[i] + W[i] | 0;
            const T2 = Σ0(a) + ((a & b) ^ (a & c) ^ (b & c)) | 0;
            h = g;
            g = f;
            f = e;
            e = d + T1 | 0;
            d = c;
            c = b;
            b = a;
            a = T1 + T2 | 0;
        }

        H[0] = H[0] + a | 0;
        H[1] = H[1] + b | 0;
        H[2] = H[2] + c | 0;
        H[3] = H[3] + d | 0;
        H[4] = H[4] + e | 0;
        H[5] = H[5] + f | 0;
        H[6] = H[6] + g | 0;
        H[7] = H[7] + h | 0;
    };

    const hash = message => {
        const m = preProcess(message);
        const H_ = H.slice();
        for (let i = 0; i < m.length; i += 16) {
            processChunk(m.slice(i, i + 16), H_);
        }
        return H_.map(toHexString).join('');
    };

    return hash(input);
}

export const apiRoot = ""

export const hasPermission = (user, permission)=>{
    if(!user)return false
    if(user.groups.permissions.map(p=>{return p.description;}).includes("admin"))return true
    return user.groups.permissions.map(p => {
        return p.description;
    }).includes(permission);

}