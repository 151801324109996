import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {apiRoot} from "../../util";


const SubmitView = () => {
    const [workstations, setWorkstations] = useState([])
    const [selectedWS, setSelectedWS] = useState(null)
    const inputRefs = useRef([]);
    const navigate = useNavigate()
    const updateSelectedWS = ()=>{
        let select = document.getElementById("selectWS").value
        if(select===-1){
            setSelectedWS(null)
            return
        }
        let sel = workstations[select];
        sel.measurePoints = sel.measurePoints.sort((a,b)=>{return (""+(a.name)).localeCompare(b.name)})
        setSelectedWS(sel)
        inputRefs.current = [];
    }
    const onSubmit = async()=>{
        let values = []
        selectedWS.measurePoints.forEach(mp=>{
            let val = Number(document.getElementById(mp.name).value)
            values.push({mpId:mp.mpId,value:val})
        })
        let res = await fetch(apiRoot+"/api/submitWS",{method:"POST",credentials: 'include',body:JSON.stringify({wsId:selectedWS.id,dataSets:values}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            alert("ERROR")
            return
        }
        selectedWS.measurePoints.forEach(mp=>{
           document.getElementById(mp.name).value=""
        })
    }
    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (index < selectedWS.measurePoints.length - 1) {
                inputRefs.current[index + 1].focus();
            } else {
                onSubmit().then(()=>{inputRefs.current[0].focus();})
            }
        }
    };

    const loadData = async ()=>{
        let res = await fetch(apiRoot+"/api/workstations",{credentials: 'include'})
        if(res.status!==200) {
            navigate("/login")
            return
        }
        res = await res.json()
        res = res.filter(ws=>{return ws.measurePoints.length!==0})
        setWorkstations(res)
    }
    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={"card"}>
            <h3>Submit Data</h3>
            <select id={"selectWS"} onChange={updateSelectedWS}>
                <option key={-1} value={-1}>select a workstation</option>
                {workstations.map((w, i)=>{
                return (<option key={i} value={i}>{w.name}</option>)
            })}</select>
            {!selectedWS?<></>:<>
                {selectedWS.measurePoints.map((mp,index)=>{
                    return (<div style={{margin:"5px"}}>
                        <label htmlFor={mp.name}>{mp.name}: </label>
                        <input type="number" id={mp.name} placeholder={mp.name} ref={el => inputRefs.current[index] = el}
                               onKeyDown={(e) => handleKeyDown(e, index)}/>
                    </div>)
                })}
                <button onClick={onSubmit}>Submit</button>
            </>}
        </div>
    );
};

export default SubmitView;