// src/components/ControlLimitsChart.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ControlLimitsChart = ({ data, controlLimits }) => {
    const chartData = {
        labels: data.map(d=>d.time.split("T").join(" ").split(":").slice(0,2).join(":")),
        datasets: [
            {
                label: 'Values',
                data: data.map(d=>d.value),
                borderColor: 'black',
                fill: false,
            },
            {
                label: 'Limit',
                data: new Array(data.length).fill(controlLimits.upper),
                borderColor: 'red',
                borderDash: [10, 5],
                fill: false,
            },
            {
                label: 'Limit',
                data: new Array(data.length).fill(controlLimits.lower),
                borderColor: 'red',
                borderDash: [10, 5],
                fill: false,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Values with Control Limits',
            },
        },
        animations: {
            tension: {
                duration: 1,
                easing: 'linear',
                from: 0,
                to: 0.4,
                loop: false
            }
        },
        animation: {
            duration: 0
        },
        scales: {
            x: {
                display: false
            }
        }
    };

    return <Line data={chartData} options={options} />;
};

export default ControlLimitsChart;
