import React, {useContext, useEffect, useState} from 'react';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import UserElement from "./userManagement/UserElement";
import "../styles.css"
import Cookies from "js-cookie";
import {apiRoot} from "../util";
import {UserContext} from "./userManagement/UserContextProvider";
const MainPage = () => {
    const linkStyle = {color: "white",textDecoration: "none", padding:"3px 12px", border: "1px solid", borderRadius:"5px", display: "inline-block", margin: "5px"}
    const navigate = useNavigate();
    const [menuOptions, setMenuOptions] = useState([]);
    const {user, updateUser} = useContext(UserContext)
    const disableSession = ()=>{
        Cookies.remove("session")
        updateUser(null)
        navigate("/login")
    }
    const checkSession = async ()=>{
        let locTok = window.location.href.split("/")
        if(["login","register"].includes(locTok[locTok.length-1]))return
        if(locTok[locTok.length-1]==="")navigate("/dashboard")
        if(user===null) {

            let session = Cookies.get("session")
            if(session){
                let res = await fetch(apiRoot+"/api/user",{method:"GET",credentials:"include"})
                if(res.status===200){
                    let user = await res.json()
                    updateUser(user)
                    console.log(user)
                    return
                }
                else {
                    disableSession()
                    return
                }
            }
            navigate("/login")
        }
        let res = await fetch(apiRoot+"/api/checkSession",{credentials:"include"})
        if(res.status!==200){
            disableSession()
        }
        res = await res.json()
        if(!res)disableSession()
    }
    /**
     *
     *                         {document.cookie.includes("username")?<>
     *                         <Link style={linkStyle} to="/dashboard">Dashboard</Link>
     *                         {Cookies.get("isAdmin")==="true"?<Link style={linkStyle} to="/admin">Admin panel</Link>:<></>}
     *                         <Link style={{...linkStyle}} to="/submit">Submit Values</Link>
     *                         </>:<></>}
     */
    const generateOptions = async()=>{
        let options = []
        if(user===null){
            setMenuOptions([])
            return
        }
        let permissions = user.groups.permissions.map(p=>{return p.description})
        options.push(<Link style={linkStyle} to="/dashboard">Dashboard</Link>)
        if(permissions.includes("admin"))options.push(<Link style={linkStyle} to="/admin">Admin panel</Link>)
        if(permissions.includes("admin")||permissions.includes("submit values"))options.push(<Link style={{...linkStyle}} to="/submit">Submit Values</Link>)
        setMenuOptions(options)
    }
    useEffect(() => {
        setInterval(checkSession,30000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        checkSession()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.location.href]);
    useEffect(() => {
        generateOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href,user]);
    return (
        <div>
            <header style={{height:"10vh"}}>
                <nav className="navbar">
                    <div style={{display: "inline-block"}}>
                        <h1 style={{display: "inline-block", margin: "10px"}}>OptiData</h1>
                        {menuOptions.map(m => {
                            return m
                        })}
                    </div>
                    <UserElement style={{display: "inline-block", margin: "15px"}}/>
                </nav>
            </header>
            <main style={{textAlign:"center", display: "flex", width:"100%", height:"90%"}}>
                <Outlet/>
            </main>
        </div>
    );
};

export default MainPage;