import React, {useState} from 'react';
import {apiRoot, shaHash} from "../../util";
import {Link, useNavigate} from "react-router-dom";

const RegisterElement = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(<></>)
    const [passwordStatus, setPasswordStatus] = useState("")
    const [passwordValid, setPasswordValid] = useState(false)

    const handleRegister=async ()=>{
        let username = document.getElementById("usrnm").value
        let password = await shaHash(document.getElementById("pwd").value)
        let passwordConf = await shaHash(document.getElementById("pwdConf").value)
        if(password!==passwordConf){
            sendError("passwords do not match")
            return
        }
        if(!passwordValid){
            sendError("password invalid")
            return
        }
        let res = await fetch(apiRoot+"/api/register",{method:"POST", body:JSON.stringify({username: username, passHash: password}),headers:{'Content-Type': 'application/json'}})
        if(res.status===409) {
            sendError("That user already exists")
            return
        }
        res = await res.json()
        document.cookie = "session="+res.session
        navigate("/dashboard")
    }
    const checkPassword = async ()=>{
        let p1 = document.getElementById("pwd").value
        let p2 = document.getElementById("pwdConf").value
        setPasswordStatus("")
        setPasswordValid(true)
        let ps = ""
        if(p1!==p2){
            ps+="- doesn't match\n"
            setPasswordValid(false)
        }
        if(p1.length<8){
            ps+="- too short\n"
            setPasswordValid(false)
        }
        if(p1.toLowerCase()===p1&&false){
            if(!ps.includes("needs to include"))ps+="needs to include\n"
            ps+="   - uppercase characters\n"
            setPasswordValid(false)
        }
        if(p1.toUpperCase()===p1){
            if(!ps.includes("needs to include"))ps+="needs to include\n"
            ps+="   - lowercase characters\n"
            setPasswordValid(false)
        }
        if(!(/\d/.test(p1))){
            if(!ps.includes("needs to include"))ps+="needs to include\n"
            ps+="   - numbers\n"
            setPasswordValid(false)
        }
        if(ps!=="")ps="Password\n"+ps
        setPasswordStatus(ps)
    }
    const sendError = (msg)=>{
        setError(<div className={"errorcard"}>
            <h2>ERROR</h2>
            <h3>{msg}</h3>
        </div>)
        setTimeout(()=>{setError(<></>)},5000)
    }
    return (
        <div className={"workerCard"}>
            {error}
            <h2 style={{marginTop:"auto"}}>Register</h2>
            <div>
                <input type="text" id="usrnm" placeholder="username"/>
            </div>
            <div style={{marginTop:"5px"}}>
                <input type="password" id="pwd" placeholder="password" onChange={checkPassword}/>
            </div>
            <div style={{marginTop:"5px"}}>
                <input type="password" id="pwdConf" placeholder="confirm password" onChange={checkPassword}/>
            </div>
            {passwordStatus!==""?<p style={{whiteSpace: "pre-wrap", background: "#dbb", padding: "5px", borderRadius: "5px"}}>
                {passwordStatus}
            </p>:<></>}
            <button onClick={handleRegister} style={{fontSize: "16px", marginTop:"5px", }} disabled={!passwordValid}>Register</button>
            <br/>
            <Link to={"/login"}>Already have an account? Login here</Link>
        </div>
    );
};

export default RegisterElement;