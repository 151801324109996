import React, { createContext, useState } from 'react';

// Create a context with default value
export const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
    // This state will hold the user object
    const [user, setUser] = useState(null);

    // This function will update the user object
    const updateUser = (newUser) => {
        setUser(newUser);
    };

    return (
        <UserContext.Provider value={{ user, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};