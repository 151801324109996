import React, {useContext, useState} from 'react';
import {Handle, Position} from 'react-flow-renderer';
import "./nodeStyles.css"
import {SelectedContext} from "./SelectedContext";
import {useNavigate} from "react-router-dom";
import {apiRoot, hasPermission} from "../../../util";
import {UserContext} from "../../userManagement/UserContextProvider";

const MyNode = ({data}) => {
    const [hover, setHover] = useState(false)
    const {user} = useContext(UserContext)
    function splitStringIntoLines(str, maxLength = 20, maxLines = 3) {
        const lines = [];
        let currentLine = '';

        for (let char of str) {
            currentLine += char;

            if (currentLine.length >= maxLength) {
                lines.push(currentLine);
                currentLine = '';

                if (lines.length === maxLines) {
                    lines[maxLines - 1] = lines[maxLines - 1].slice(0, -3) + '...';
                    return lines.join('\n');
                }
            }
        }

        if (currentLine) {
            lines.push(currentLine);
        }

        if (lines.length > maxLines) {
            lines[maxLines - 1] = lines[maxLines - 1].slice(0, -3) + '...';
            lines.length = maxLines;
        }

        return lines.join('\n');
    }

    const {selected, setSelected} = useContext(SelectedContext)
    const navigate = useNavigate();
    const handleConnectionRequest=async()=>{
        if(selected===-1)setSelected(data.id)
        else{
            if(data.in.includes(selected)||data.out.includes(selected)){

                let source = 0;
                let target = 0;
                if(data.in.includes(selected)){
                    target=data.id;
                    source=selected;
                }else{
                    source=data.id;
                    target=selected;
                }
                let res = await fetch(apiRoot+"/api/removeLink",{method:"POST",credentials: 'include',body:JSON.stringify({source:source,target:target}),headers:{"Content-Type":"application/json"}})
                if(res.status!==200){
                    alert("ERROR")
                    return
                }
            }else if(selected!==data.id){
                let res = await fetch(apiRoot+"/api/link",{method:"POST",credentials: 'include',body:JSON.stringify({source:selected,target:data.id}),headers:{"Content-Type":"application/json"}})
                if(res.status!==200){
                    if(res.status===409){
                        alert("Cannot create circular connection!")
                        return
                    }
                    alert("ERROR")
                    return
                }
            }
            setHover(false)
            setSelected(-1)
            data.updateWS()
        }
    }
    const getButtonColor = ()=>{
        if(!hover){
            if (selected === -1) return "#f5f5f5"
            else if (data.id === selected) return "#999999"
            else if (data.in.includes(selected) || data.out.includes(selected)) return "#ff0000"
            else return "#00dd00"
        }
        else {
            if (selected === -1) return "#bbb"
            else if (data.id === selected) return "#777"
            else if (data.in.includes(selected) || data.out.includes(selected)) return "#bb0000"
            else return "#00aa00"
        }
    }
    const getButtonText = ()=>{
        if(selected===-1)return "connect/disconnect"
        else if(data.id===selected)return "cancel"
        else if(data.in.includes(selected)||data.out.includes(selected))return "disconnect"
        else return "connect"
    }
    return (
        <div className={"node"} style={{background:data.color}}>
            <div className="nodelabel" style={{whiteSpace: "pre-line"}}><strong>{splitStringIntoLines(data.label)}</strong> </div>
            <div className={"viewbuttoncontainer"}>
                <button className={"viewbutton"} onClick={() => {
                   navigate("/workstation/" + data.id)
                }}>view</button>
            </div>
            {(hasPermission(user,"admin")||hasPermission(user,"manage workstations"))?<div className={"viewbuttoncontainer"}>
                <button className={"viewbutton"} onMouseEnter={() => {
                    setHover(true)
                }} onMouseLeave={() => {
                    setHover(false)
                }} style={{background: getButtonColor()}} onClick={() => {
                    handleConnectionRequest()
                }}>{getButtonText()}</button>
            </div>:<></>}
            {data.in.length !== 0 ?
                <Handle type="target" position={Position.Left} style={{background: '#555'}} id={(data.id)+"i"}/> : <></>}
            {data.out.length !== 0 ?
                <Handle type="source" position={Position.Right} style={{background: '#555'}} id={(data.id)+"e"}/> : <></>}
        </div>
    );
};

export default MyNode;
