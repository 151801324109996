export const coordinateWorkstations = (origArr, stepSizeX = 100, stepSizeY = 75) => {
    let newArr = []
    origArr = origArr.map(e => {
        e.out=[]
        e.data={label:e.name, id:e.id,in:e.predecessors,out:[], color:e.color}
        e.id = Number(e.id);
        e.in = e.predecessors.map(n=>{return Number(n)})
        return e
    })
    origArr.forEach(w=>{
        origArr.forEach(w2=>{
            if(w.in.includes(w2.id)) {
                w2.out.push(w.id)
                w2.data.out.push(w.id)
            }
        })
    })
    for (let i = 0; i < origArr.length; i++) {
        let e = origArr[i]
        if (e.in.length === 0) {
            newArr.push(e);
            origArr = [...origArr.slice(0, i), ...origArr.slice(i + 1, origArr.length)]
            i--
        }
    }
    for (let i = 0; origArr.length > 0&&i<999; i++) {
        let ind = i % origArr.length;
        let e = origArr[ind]
        for (let j = 0; j < newArr.length; j++) {
            if (newArr[j].id === e.in[e.in.length - 1]) {
                newArr = [...newArr.slice(0, j), e, ...newArr.slice(j, newArr.length)]
                origArr = [...origArr.slice(0, ind), ...origArr.slice(ind + 1, origArr.length)]
                break
            }
        }
    }
    //newArr = optimizedFunction(origArr, newArr)
    newArr = newArr.reverse()
    let outArr = []
    let cellPointerX = 0;
    let cellPointerY = 0;
    for (let i = 0; i < newArr.length; i++) {
        let e = newArr[i]
        if (e.in.length === 0) {
            outArr.push([e])
            cellPointerX = 0;
            cellPointerY = outArr.length - 1;
            continue;
        }
        if (e.in[e.in.length - 1] === outArr[outArr.length - 1][outArr[outArr.length - 1].length - 1].id) {
            outArr[cellPointerY].push(e)
            cellPointerX++;
            continue;
        }
        outArr.push([]);
        while (outArr[cellPointerY][cellPointerX] == null ? true : !e.in.includes(outArr[cellPointerY][cellPointerX].id)) {
            if (outArr[cellPointerY][cellPointerX] != null) cellPointerX--;
            else {
                cellPointerY--;
            }
        }
        while (outArr[cellPointerY].length > 0) cellPointerY++;
        for (let j = 0; j <= cellPointerX; j++) outArr[cellPointerY].push(null)
        outArr[cellPointerY].push(e)
        cellPointerX++;
    }
    for (let i = 0; i < outArr.length; i++) {
        for (let j = 0; j < outArr[i].length; j++) {
            if (outArr[i][j] != null) {
                outArr[i][j].x = stepSizeX * j;
                outArr[i][j].y = stepSizeY * i;
            }
        }
    }

    outArr = outArr.flatMap(row => row.filter(e => e !== null))
    return outArr
}


export const createEdges = (inArr, animated = true) => {
    let edges = [];
    inArr.forEach(n => {
        n.in.forEach(e => {
            edges.push({ id: `e${e}->${n.id}`, animated: animated, source: e + "", target: n.id + "" ,sourceHandle: e + "e", targetHandle: n.id + "i" })
        })
    })
    return edges
}