import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {UserContext} from "./UserContextProvider";

const UserElement = () => {
    const {user} = useContext(UserContext)
    return (
        <div>
            {user===null ? <>
            <Link to={"/login"} style={{textDecoration:"none",color:"#fff",margin:"10px", padding:"6px", borderRadius:"5px", background:"#555"}}>
                Login
            </Link>
                <Link to={"/register"} style={{textDecoration:"none",color:"#fff",margin:"10px", padding:"6px", borderRadius:"5px", background:"#555"}}>
                    Register
                </Link>
            </> : <>
                <Link to={"/settings"} style={{textDecoration: "none",
                    color: "#fff",
                    margin: "10px",
                    padding: "6px",
                    borderRadius: "5px",
                    background: "#555",
                    display: "flex",
                    alignItems: "center" }}>
                    <img src={"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2Fd%2Fn%2Fq%2Fj%2FM%2F2%2Fcog-cogwheel-outline-hi.png&f=1&nofb=1&ipt=e5789ffac623d47ac58aef06bb7b43a9788b1748887e6ab45ac0e64684fb83a7&ipo=images"} height={"14px"} style={{marginRight:"5px"}} alt={""}/>
                    {user.username}
                </Link>
            </>}

        </div>
    );
};

export default UserElement;