import React from 'react';
import UserManager from "./UserManager";
import GroupManager from "./GroupManager";

const AdminPanel = () => {
    return (
        <div>
            <UserManager/>
            <GroupManager/>
        </div>
    );
};

export default AdminPanel;