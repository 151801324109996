import React, {useCallback, useEffect, useState} from 'react';
import ReactFlow, {applyEdgeChanges, applyNodeChanges} from "react-flow-renderer";
import MyNode from './nodes/Node.jsx';
import {coordinateWorkstations, createEdges} from "./misc/SpawnAndSort";
import {SelectedProvider} from "./nodes/SelectedContext";
import {apiRoot} from "../../util";

const spawnAlgo = (workstations) => {
    let nodes = [];
    workstations.forEach(w => {
        let type = 'node'
        nodes.push(
            {
                id: `${w.id}`,
                type: type,
                data: w.data,
                position: {x: w.x, y: w.y},
                draggable: false
            })
    })

    return nodes;
}

const nodeTypes = {node: MyNode};
const FlowChart = () => {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [error, setError] = useState(<></>);
    const [loadingView, setLoading] = useState(<div className={"whiteText"}><h1>Loading...</h1></div>);
    const [workstations, setWorkstations] = useState([])
    const updateWorkstations = async ()=>{
        let res = await fetch(apiRoot+"/api/workstations",{credentials: 'include'})
        setLoading(<></>)
        if(res.status!==200) {
            setError(<div className={"error"}>
                <h1>ERROR:</h1>
                <h2>Missing permissions</h2>
            </div>)
            return
        }
        res = await res.json()
        setWorkstations(res)
        let sortedStations = coordinateWorkstations(res, 470, 250)
        let ndes = spawnAlgo(sortedStations)
        ndes.forEach(n=>{n.data.updateWS = updateWorkstations})
        setNodes(ndes)
        setEdges(createEdges(sortedStations))
    }

    useEffect(() => {
        updateWorkstations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEdges(createEdges(coordinateWorkstations(workstations)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyNodeChanges,nodes]);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );


    return (
        <>
            {error}
            {loadingView}
            <SelectedProvider>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes}
                    proOptions={{hideAttribution: true}}
                    fitView
                >
                </ReactFlow>
            </SelectedProvider>
        </>
    );
};

export default FlowChart;
