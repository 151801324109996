import React, {useContext, useState} from 'react';
import {apiRoot, shaHash} from "../../util";
import {useNavigate} from "react-router-dom";
import {UserContext} from "./UserContextProvider";

const SettingsView = () => {
    const navigate = useNavigate();
    const {user, updateUser} = useContext(UserContext)
    const [notification, setNotification] = useState(<></>)
    const onLogout=()=>{
        updateUser(null)
        navigate("/login")
    }
    const onChangePassword=async()=>{
        let oldPass = document.getElementById("curr").value
        let new1 = document.getElementById("new1").value
        let new2 = document.getElementById("new2").value
        if(!(new1===new2)){
            alert("Passwords do not match!")
            return
        }
        oldPass = await shaHash(oldPass)
        new1 = await shaHash(new1)
        let res = await await fetch(apiRoot+"/api/changepass",{method:"POST",credentials: 'include',body:JSON.stringify({origHash:oldPass,newHash:new1}),headers:{"Content-Type":"application/json"}})
        if(res.status!==200){
            if(res.status===409){
                setNotification(<div className={"alertcard"}>
                    <h2>ALERT</h2>
                    <h3>Password unchanged</h3>
                    <p>new and old password are the same</p>
                </div>)
                return
            }
            else if(res.status===403){
                setNotification(<div className={"errorcard"}>
                    <h2>ERROR</h2>
                    <h3>Password incorrect</h3>
                    <p></p>
                </div>)
                return
            }
        }
        else
        setNotification(<div className={"infocard"}>
            <h2>SUCCESS</h2>
            <h3>Password successfully changed</h3>
            <p></p>
        </div>)
        document.getElementById("curr").value = ""
        document.getElementById("new1").value = ""
        document.getElementById("new2").value = ""
    }
    return (
        <div style={{textAlign:"center"}}>
            <h2 className={"whiteText"}>{user?user.username:""}</h2>
            <div className={"card"} style={{display:"block"}}>
                {notification}
                <h3>Change password</h3>
                <input id={"curr"} placeholder={"current password"} style={{display:"block", margin:"5px"}} type={"password"}/>
                <input id={"new1"} placeholder={"new password"} style={{display:"block", margin:"5px"}} type={"password"}/>
                <input id={"new2"} placeholder={"confirm password"} style={{display:"block", margin:"5px"}} type={"password"}/>
                <button onClick={onChangePassword}>Change Password</button>
            </div>
            <button onClick={onLogout}>Logout</button>
        </div>
    );
};

export default SettingsView;